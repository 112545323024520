import type { IDynamicForm } from "../../interfaces";

export const formInvertersSolar: IDynamicForm[] = [
    {
        name: "inverter_brand",
        label: "Marca inversor",
        type: "text",
        sx: { xs: 12 },
    },
    {
        name: "inverter_model",
        label: "Modelo inversor",
        type: "text",
        sx: { xs: 12 },
    },
    {
        name: "inverter_serial",
        label: "Serial inversor",
        type: "text",
        sx: { xs: 12 },
    },
];
