import type { IPicturesInspectionSolar } from "../../../interfaces/solar/IEmissionTabContentSolar";
import type {
    AccessInstallation,
    DataTelemetry,
    IInputDataFormDataSolar,
    OneLineSolutionDiagram,
    SfvInformation,
} from "../../../interfaces/solar/IRespOutputDataSolar";

export const getAllPicturesByInspectionFormSolar = (
    formData: IInputDataFormDataSolar,
): IPicturesInspectionSolar => ({
    sfv_information: getSfvInformationSolar(formData.sfv_information ?? null),
    access_installation: getAccessInstallationSolar(
        formData.access_installation ?? null,
    ),
    data_telemetry: getDataTelemetrySolar(formData.data_telemetry ?? null),
    one_line_diagram: getOneLineDiagramSolar(
        formData.one_line_diagram?.one_line_solution_diagram ?? null,
    ),
});

const getAccessInstallationSolar = (
    general_data?: AccessInstallation | null,
) => {
    if (!general_data) {
        return [];
    }
    const photoMapping = [
        {
            title: "Espacio de instalación",
            key: "path_installation_meter_smartbit",
        },
        { title: "Ruta", key: "photo_route" },
    ] as const;

    const arrayPictures = photoMapping.map(({ title, key }) => ({
        image_title: title,
        image_url: general_data[key]
            .filter((value) => value.picture_url)
            .map((value, index) => ({
                image_title: `${title} ${index}`,
                image_url: value.picture_url,
            })),
    }));

    return arrayPictures;
};

const getDataTelemetrySolar = (general_data?: DataTelemetry | null) => {
    if (!general_data) {
        return [];
    }
    const photoMapping = [
        { title: "Salida de datos", key: "output_data" },
    ] as const;

    const arrayPictures = photoMapping.map(({ title, key }) => ({
        image_title: title,
        image_url: general_data[key]
            .filter((value) => value.picture_url)
            .map((value, index) => ({
                image_title: `${title} ${index}`,
                image_url: value.picture_url,
            })),
    }));

    return arrayPictures;
};

const getOneLineDiagramSolar = (
    one_line_solution_diagrams?: OneLineSolutionDiagram[] | null,
) => {
    if (
        !one_line_solution_diagrams ||
        one_line_solution_diagrams.length === 0
    ) {
        return [];
    }

    const arrayPictures = one_line_solution_diagrams
        .filter((value) => value.one_line_solution_diagram?.picture_url)
        .flatMap((item, index) =>
            item.one_line_solution_diagram
                ? [
                      {
                          image_title: `Salida de datos ${index}`,
                          image_url: item.one_line_solution_diagram.picture_url,
                      },
                  ]
                : [],
        );

    return arrayPictures;
};

const getSfvInformationSolar = (general_data?: SfvInformation | null) => {
    if (!general_data) {
        return [];
    }
    const photoMapping = [
        { title: "Topología", key: "topology" },
        {
            title: "Inversor",
            key: "picture_inverter",
        },
        {
            title: "Serial del inversor",
            key: "picture_inverter_serial",
        },
        {
            title: "Paneles solares",
            key: "solar_panels",
        },
        {
            title: "Conectores eléctricos salida del inversor",
            key: "picture_inverter_connector",
        },
        {
            title: "Caja de distribución",
            key: "picture_inverter_distribution",
        },
        {
            title: "Inversor con elementos eléctricos cercanos ( foto de lejos )",
            key: "picture_inverter_electric_elements",
        },
        {
            title: "Gabinete de desconexión solar ( breakers )",
            key: "picture_disconnection_cabinet",
        },
        {
            title: "Todos los gabinetes cercanos adicionales abiertos",
            key: "picture_all_nearby_cabinets",
        },
    ] as const;

    const arrayPictures = photoMapping.map(({ title, key }) => ({
        image_title: title,
        image_url: general_data[key]
            ? general_data[key]
                  ?.filter((value) => value.picture_url)
                  .map((value, index) => ({
                      image_title: `${title} ${index}`,
                      image_url: value.picture_url,
                  }))
            : [],
    }));
    return arrayPictures;
};
