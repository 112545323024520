import {
    Grid,
    LoadingButton,
    api,
    decodedJwt,
    useSnackbar,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import PendingIcon from "@mui/icons-material/Pending";
import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { mutate } from "swr";
import { EmissionDialog } from ".";
import { formDiagnostic } from "../../form-builders";
import {
    calculateTotal,
    formatPrice,
    restoreTransformMaterials,
} from "../../helpers";
import type { IDiagnosticFormData } from "../../interfaces";
import { PatchEmitDiagnosticSchema } from "../../schemas";
import { useEmissionStore } from "../../store";
import { useDiagnosticsStore } from "../../store/diagnostics.store";
import { FormItem } from "../dynamic-form";

interface Props {
    handleClose: () => void;
}

export const PatchEmitDiagnosticForm: FC<Props> = ({ handleClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const {
        materials,
        previous_diagnostic,
        updatePreviousDiagnostic,
        material_quotation,
    } = useEmissionStore();

    const { page, size, selectedStatus } = useDiagnosticsStore();

    const total = calculateTotal(materials);
    const totalQuotation = calculateTotal(material_quotation);
    const hasNoCharge = material_quotation.some(
        (material) => !material.charge || material.charge === "0",
    );

    const getUserInfo = async () => {
        const res = await decodedJwt();
        return {
            name: res?.full_name ?? "",
            email: res?.name ?? "",
            operator_id: res?.sub ?? "",
        };
    };

    const methods = useForm<IDiagnosticFormData>({
        resolver: zodResolver(PatchEmitDiagnosticSchema),
        defaultValues: {
            diagnostic: {
                ...previous_diagnostic?.form_data.diagnostic,
                status:
                    previous_diagnostic?.form_data.diagnostic.status ===
                        "pending_quotation" && !hasNoCharge
                        ? "quoted"
                        : previous_diagnostic?.form_data.diagnostic.status,
                reasons_update: "",
                of_type: "field",
            },
            materials_and_labor: {
                materials: restoreTransformMaterials(materials),
                total_price: formatPrice(total),
            },
            material_quotation: {
                materials: restoreTransformMaterials(material_quotation),
                total_price: formatPrice(totalQuotation),
            },
            emited_by: null,
        },
        mode: "onChange",
    });

    const { getValues, watch, trigger, setValue } = methods;
    const status = watch("diagnostic.status");
    const measurementType = watch("diagnostic.measurement_type");

    useEffect(() => {
        if (
            measurementType === "semidirecta" ||
            measurementType === "indirecta"
        ) {
            setValue("diagnostic.current_transformer", "Aplica");
        } else {
            setValue("diagnostic.current_transformer", null);
        }
        if (measurementType === "indirecta") {
            setValue("diagnostic.voltage_transformer", "Aplica");
        } else {
            setValue("diagnostic.voltage_transformer", null);
        }
    }, [measurementType, setValue]);

    useEffect(() => {
        if (status === "ok") {
            setValue("diagnostic.comment_or", "");
            setValue("diagnostic.observation_work_order", "");
            setValue("diagnostic.comment_bussiness", null);
            setValue("diagnostic.comment_quotation", null);
        } else if (status === "pending_quotation" || status === "quoted") {
            setValue("diagnostic.comment_quotation", "");
            setValue("diagnostic.comment_or", null);
            setValue("diagnostic.observation_work_order", null);
            setValue("diagnostic.comment_bussiness", null);
        } else {
            setValue("diagnostic.comment_or", null);
            setValue("diagnostic.observation_work_order", null);
            setValue("diagnostic.comment_bussiness", "");
            setValue("diagnostic.comment_quotation", null);
        }
    }, [status, setValue]);

    const handleOpenDialog = async () => {
        if (await trigger()) setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        if (await trigger()) {
            const values = getValues();
            await onSubmit(values);
            handleCloseDialog();
        }
    };

    const onSubmit = async (data: IDiagnosticFormData) => {
        try {
            setIsLoading(true);
            const userInfo = await getUserInfo();
            const diagnostic: IDiagnosticFormData = {
                ...data,
                emited_by: userInfo,
            };
            await api.patch(
                `/frontier-initial-diagnostic/emit-diagnostic?_id=${previous_diagnostic?.id}`,
                diagnostic,
            );
            await mutate(
                `/frontier-initial-diagnostic/diagnostics?page=${page}&size=${size}${
                    selectedStatus ? `&status=${selectedStatus}` : ""
                }`,
            );
            updatePreviousDiagnostic(diagnostic);
            enqueueSnackbar("Diagnóstico editado con éxito.", {
                variant: "success",
            });
            handleClose();
        } catch (_error) {
            enqueueSnackbar(
                "No se pudo editar el diagnóstico, inténtelo de nuevo.",
                {
                    variant: "error",
                },
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container spacing={3}>
                    {formDiagnostic.map((item, index) => {
                        const isDisabled =
                            item.name === "status" &&
                            previous_diagnostic?.form_data.diagnostic.status ===
                                "ok";
                        const newItem = {
                            ...item,
                            name: `diagnostic.${item.name}`,
                            ...(item.name === "status" && {
                                props: {
                                    disabled: isDisabled,
                                },
                            }),
                        };

                        if (
                            newItem.name === "diagnostic.status" &&
                            hasNoCharge &&
                            material_quotation.length > 0
                        ) {
                            newItem.props = {
                                ...newItem.props,
                                disabled: true,
                            };
                            newItem.items = [
                                {
                                    text: "Pendiente de cotización",
                                    value: "pending_quotation",
                                    icon: PendingIcon,
                                    color: "secondary",
                                },
                            ];
                        } else if (
                            newItem.name === "diagnostic.status" &&
                            !hasNoCharge &&
                            previous_diagnostic?.form_data.diagnostic.status ===
                                "pending_quotation" &&
                            material_quotation.length > 0
                        ) {
                            newItem.props = {
                                ...newItem.props,
                            };
                            newItem.items = [
                                ...(newItem.items ? newItem.items : []),
                                {
                                    text: "Cotizado",
                                    value: "quoted",
                                    icon: PendingIcon,
                                    color: "secondary",
                                },
                            ];
                        }

                        return <FormItem {...newItem} key={index.toString()} />;
                    })}
                    {(measurementType === "semidirecta" ||
                        measurementType === "indirecta") && (
                        <Grid item xs={12}>
                            <FormItem
                                name="diagnostic.current_transformer"
                                label="Transformadores de corriente"
                                type="text"
                                sx={{ xs: 12, md: 6, lg: 4 }}
                            />
                        </Grid>
                    )}
                    {measurementType === "indirecta" && (
                        <Grid item xs={12}>
                            <FormItem
                                name="diagnostic.voltage_transformer"
                                label="Transformadores de tensión"
                                type="text"
                                sx={{ xs: 12, md: 6, lg: 4 }}
                            />
                        </Grid>
                    )}
                    {status === "ok" ? (
                        <>
                            <Grid item xs={12}>
                                <FormItem
                                    name="diagnostic.comment_or"
                                    label="Comentarios para el OR"
                                    type="text-area"
                                    sx={{ xs: 12 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormItem
                                    name="diagnostic.observation_work_order"
                                    label="Observación para orden de servicio"
                                    type="text-area"
                                    sx={{ xs: 12 }}
                                />
                            </Grid>
                        </>
                    ) : status === "pending_quotation" ||
                      status === "quoted" ? (
                        <Grid item xs={12}>
                            <FormItem
                                name="diagnostic.comment_quotation"
                                label="Comentarios sobre la cotización"
                                type="text-area"
                                sx={{ xs: 12 }}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <FormItem
                                name="diagnostic.comment_bussiness"
                                label="Comentarios comercial"
                                type="text-area"
                                sx={{ xs: 12 }}
                            />
                        </Grid>
                    )}
                    <FormItem
                        type={"text-area"}
                        name="diagnostic.reasons_update"
                        label="Razones de la actualización"
                        sx={{ xs: 12 }}
                    />
                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isLoading}
                            onClick={
                                watch("diagnostic.status") === "ok"
                                    ? handleOpenDialog
                                    : handleConfirmDialog
                            }
                            color="secondary"
                            variant="contained"
                            fullWidth
                        >
                            Editar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <EmissionDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDialog}
            />
        </FormProvider>
    );
};
