import { api } from "@enerbit/base";
import { AxiosError } from "axios";
import { create } from "zustand";
import { generateConceptSolar } from "../helpers/concept/generateConceptSolar";
import { generateInverters } from "../helpers/incoming-data/generateSolarData";
import { generateEditableData } from "../helpers/incoming-data/solar/generateEditableData";
import { generateIncomingDataSolar } from "../helpers/incoming-data/solar/generateIncomingDataSolar";
import { obtainsInspectionOrPreviousDiag } from "../helpers/incoming-data/solar/generateformData";
import { transformMaterials } from "../helpers/material-labor";
import { getAllPicturesByInspectionFormSolar } from "../helpers/photographs/solar/generatePhotographsSolar";
import type { IEmissionStoreSolar } from "../interfaces/IEmissionStoreSolar";
import type {
    IRespOutputDataSolar,
    Inverter,
} from "../interfaces/solar/IRespOutputDataSolar";

const initialState = {
    installation: null,
    outputData: null,
    loadingEmission: false,
    incomingDataSolar: {
        header: [],
        sfvInformation: [],
        accessInstallation: [],
        observations: [],
        dataTelemetry: [],
    },
    photographs: null,
    invertersSolar: [],
    materialsSolar: {
        materials_meter: [],
        materials_smartbit: [],
    },
    concept: [],
    previous_diagnostic: null,
    errorMessage: "",
    formDataSolar: null,
    initialInspectionId: "",
    invertersOld: [],
    formEditableData: null,
    enableButton: true,
};

const getInverters = (inverters: Inverter[]) => {
    return inverters?.map((inverter) => ({
        inverter_brand:
            (inverter.inverter.inverter_brand === "Otro"
                ? inverter.inverter.inverter_brand_new
                : inverter.inverter.inverter_brand) ?? "",
        inverter_model:
            (inverter.inverter.inverter_model === "Otro"
                ? inverter.inverter.inverter_model_new
                : inverter.inverter.inverter_model) ?? "",
        inverter_serial: inverter.inverter.inverter_serial,
    }));
};

export const useEmissionStoreSolar = create<IEmissionStoreSolar>()((set) => ({
    ...initialState,
    getOutputDataSolar: async (lead) => {
        try {
            const { id } = lead;
            set({
                ...initialState,
                loadingEmission: true,
                errorMessage: "",
            });
            const { data } = await api.get<IRespOutputDataSolar>(
                `/frontier-initial-diagnostic/output-solar-data/?diagnostic_type=solar&lead_id=${id}&is_field=true`,
            );

            const { field, previous_diagnostic } = data;
            const photographs = field?.input_data.form_data
                ? getAllPicturesByInspectionFormSolar(
                      field?.input_data.form_data,
                  )
                : null;

            const materialsMeterSource = previous_diagnostic
                ? previous_diagnostic.form_data.materials_and_labor
                      .materials_meter
                : field?.input_data.form_data.access_installation
                      ?.materials_meter;

            const materialsSmartbitSource = previous_diagnostic
                ? previous_diagnostic.form_data.materials_and_labor
                      .materials_smartbit
                : field?.input_data.form_data.access_installation
                      ?.materials_smartbit;

            const materialsSolar = {
                materials_meter: await transformMaterials(
                    materialsMeterSource?.filter((item) => item.name !== ""),
                ),
                materials_smartbit: await transformMaterials(
                    materialsSmartbitSource?.filter((item) => item.name !== ""),
                ),
            };

            const formDataSolar =
                field &&
                obtainsInspectionOrPreviousDiag(field, previous_diagnostic);

            const incomingDataSolar =
                formDataSolar &&
                generateIncomingDataSolar(
                    lead,
                    field?.input_data.created_at,
                    formDataSolar,
                );

            const formEditableData =
                formDataSolar && generateEditableData(formDataSolar);

            const invertersSolar = formDataSolar
                ? generateInverters(formDataSolar)
                : null;

            const concept = generateConceptSolar(formDataSolar?.observations);

            set({
                outputData: data,
                incomingDataSolar,
                invertersSolar,
                photographs,
                materialsSolar,
                concept,
                previous_diagnostic,
                loadingEmission: false,
                formDataSolar,
                initialInspectionId: field?.input_data.id,
                formEditableData,
            });
        } catch (error) {
            set({
                loadingEmission: false,
            });
            if (error instanceof AxiosError) {
                if (error.response?.status === 404) {
                    set({
                        errorMessage:
                            "Este usuario no cuenta con inspección previa.",
                    });
                } else {
                    set({
                        errorMessage: "Ha ocurrido un error desconocido.",
                    });
                }
            } else if (error instanceof Error) {
                set({
                    errorMessage: `Error inesperado: ${error.message}`,
                });
            } else {
                set({
                    errorMessage: "Error desconocido.",
                });
            }
        }
    },
    deleteMaterialSolar: (id, keyDataSolar) => {
        set((state) => ({
            materialsSolar: {
                ...state.materialsSolar,
                [keyDataSolar]: state.materialsSolar[keyDataSolar].filter(
                    (material) => material.id !== id,
                ),
            },
            formDataSolar:
                state.formDataSolar == null
                    ? null
                    : {
                          ...state.formDataSolar,
                          access_installation: {
                              ...state.formDataSolar.access_installation,
                              [keyDataSolar]: state.materialsSolar[
                                  keyDataSolar
                              ].filter((material) => material.id !== id),
                          },
                      },
        }));
    },
    updateQuantityMaterialSolar: (id, quantity, keyDataSolar) => {
        set((state) => ({
            materialsSolar: {
                ...state.materialsSolar,
                [keyDataSolar]: state.materialsSolar[keyDataSolar].map(
                    (material) =>
                        material.id === id
                            ? {
                                  ...material,
                                  quantity: quantity,
                                  subtotal:
                                      quantity *
                                      Number.parseFloat(material.charge),
                              }
                            : material,
                ),
            },
            formDataSolar:
                state.formDataSolar == null
                    ? null
                    : {
                          ...state.formDataSolar,
                          access_installation: {
                              ...state.formDataSolar.access_installation,
                              [keyDataSolar]: state.materialsSolar[
                                  keyDataSolar
                              ].map((material) =>
                                  material.id === id
                                      ? {
                                            ...material,
                                            quantity: quantity,
                                            subtotal:
                                                quantity *
                                                Number.parseFloat(
                                                    material.charge,
                                                ),
                                        }
                                      : material,
                              ),
                          },
                      },
        }));
    },
    updateEditableForm: (newValues) => {
        set(() => ({
            formEditableData: newValues,
        }));
    },
    updateSpecialTools: (newValue) => {
        set((state) => ({
            concept: [
                ...state.concept.map((item) => {
                    if (item.key === "special_tools") {
                        return { ...item, value: newValue };
                    }
                    return item;
                }),
            ],
            formDataSolar:
                state.formDataSolar == null
                    ? null
                    : {
                          ...state.formDataSolar,
                          observations: {
                              ...state.formDataSolar.observations,
                              special_tools: newValue,
                          },
                      },
        }));
    },
    updateInvertersOld: () => {
        set((state) => ({
            invertersSolar:
                state.invertersOld && state.invertersOld.length > 0
                    ? getInverters(state.invertersOld)
                    : getInverters(
                          state.formDataSolar?.sfv_information.inverters ?? [],
                      ),
        }));
    },
    deleteInverter: (id) => {
        set((state) => ({
            invertersSolar: state.invertersSolar?.filter(
                (inverter) => inverter.inverter_serial !== id,
            ),
        }));
    },
    updateInvertersAll: () => {
        set((state) => ({
            invertersOld: state.invertersSolar?.map((inverter) => {
                return {
                    inverter: {
                        ...inverter,
                        inverter_brand_new: "",
                        inverter_model_new: "",
                    },
                };
            }),
            invertersSolar: [
                {
                    inverter_brand: "GENESIS",
                    inverter_model: "III AR",
                    inverter_serial: "GENESIS",
                },
            ],
        }));
    },
    updateInverters: (newValue, index, keyDataSolar) => {
        set((state) => ({
            invertersOld: state.invertersSolar?.map((item, indexInverter) => ({
                inverter:
                    index === indexInverter
                        ? { ...item, [keyDataSolar]: newValue }
                        : item,
            })),
            invertersSolar: state.invertersSolar?.map((item, indexInverter) =>
                index === indexInverter
                    ? { ...item, [keyDataSolar]: newValue }
                    : item,
            ),
        }));
    },
    addInverters: (newValue) => {
        set((state) => ({
            invertersSolar: [...(state.invertersSolar ?? []), { ...newValue }],
        }));
    },
    addMaterialSolar: (material, keyDataSolar) => {
        set((state) => ({
            materialsSolar: {
                ...state.materialsSolar,
                [keyDataSolar]: [
                    ...state.materialsSolar[
                        keyDataSolar as keyof typeof state.materialsSolar
                    ],
                    material,
                ],
            },
            formDataSolar:
                state.formDataSolar == null
                    ? null
                    : {
                          ...state.formDataSolar,
                          access_installation: {
                              ...state.formDataSolar.access_installation,
                              [keyDataSolar]: [
                                  ...state.formDataSolar.access_installation[
                                      keyDataSolar
                                  ],
                                  material,
                              ],
                          },
                      },
        }));
    },
    createPreviousDiagnostic: (diagnostic) =>
        set({ previous_diagnostic: diagnostic }),
    updatePreviousDiagnostic: (diagnostic) =>
        set((state) => ({
            previous_diagnostic: {
                ...state.previous_diagnostic,
                form_data: diagnostic,
            } as Partial<IEmissionStoreSolar>["previous_diagnostic"],
        })),
    clearError: () => set({ errorMessage: "" }),
    enableButtonSendEmit: (value) => set({ enableButton: value }),
}));
